@import "../../../common-assets/css/variables";
@import "../../../common-assets/css/rootui-parts/profile";

.cover-photo {
  height: 300px;
  width: 100%;
  background-size: cover;
}

.store_sde-upp1 {
  position: absolute;
  bottom: 0;
  display: none;
  text-align: center;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 8px 10px 10px 10px;
  z-index: 1;
  cursor: pointer;
}

.store_sde-upp1.show {
  display: inherit;
}

.rui-dropzone .rui-dropzone-message {
  min-height: 94px !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev,
.slick-next {
  z-index: 10;
  color: #ffffff;
  height: 40px;
  background: #17191d;
  width: 40px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  line-height: 40px;
  opacity: 0.7;

  .rui-icon {
    height: 24px;
    width: 24px;
  }
}

.slick-prev:hover,
.slick-next:hover {
  color: #ffffff;
  background: #17191d;
  opacity: 1;
}

.slick-prev:before, .slick-next:before {
  content: '';
}
